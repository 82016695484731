import React from "react"
import {Container} from "react-bootstrap"
import SEO from "../components/seo"
import ValuationCTA from "../components/valuationCta";
import Layout from "../components/layout";
import {graphql} from "gatsby";

const FaqsPage = ({data}) => {

    return (
        <Layout pageInfo={{pageName: "index"}}>
            <SEO title="Frequently Asked Questions" description="These are our frequently asked questions, a list of commonly asked questions and answers for you to read. If you don't see what you need, please contact us."/>

            <Container className="slice">
                <h1 className="text-center font-weight-800 mb-5">Frequently Asked Questions</h1>

                {
                    data?.questions?.nodes.map((node) => (
                        node?.faqs?.questions.map((faq) => (
                        <div className="rounded p-4 bg-gray-100 mb-3">
                            <h4 className="text-primary-dark font-weight-800 mb-3">{faq.title}</h4>
                            <p className="mb-0">
                                { faq.answer.split("\n").map((par => (
                                        <p>{par ?? ""}</p>)))
                                }
                            </p>
                        </div>
                    )))) ?? ''
                }

            </Container>

            <ValuationCTA></ValuationCTA>

        </Layout>
    )
}


export const data = graphql`
    query FAQ{
        questions: allWpPage(filter: {
            slug: {
                eq: "faq"
            }
        }
        ){
            nodes {
                faqs {
                    questions {
                      answer
                      title
                      more
                    }
                  }
            }
        }

        file(relativePath: { eq: "sara-banner-2.png" }) {
            childImageSharp {
            fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default FaqsPage
